html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  flex: 1 0 auto;
  min-height: 80vh;
}

.navBarMain {
  margin-bottom: 50px;
}

.navBarLogo {
  display: inline-flex;
  vertical-align: middle;
}

.landingPageLogo {
  justify-content: center;
}

.landingPageParagraph {
  text-align: justify;
}